import {
  AdCampaignCheckpoint,
  AdResolutionIab,
  AdSurfaceInteractivity,
  AdType,
  CampaignAssetType,
  CampaignGoal,
  GameStatus,
  Gender,
  NotificationType,
  TransactionStatus,
  TransactionType,
  UserRole,
} from '../consts';

import { Dispatch, SetStateAction } from 'react';
import { ApolloQueryResult, OperationVariables } from '@apollo/client';
import { IconName } from '../../components/Icon/Icon';

export type Dispatcher<S> = Dispatch<SetStateAction<S>>;

export type Refetch<S> = (variables?: OperationVariables | undefined) => Promise<ApolloQueryResult<S>>;

export type MenuItems = { [key: string]: { name: string; icon: IconName; label: string } };

export type OrgType = 'ADVERTISEMENT' | 'CREATOR' | 'GAME' | 'GENERAL';

export type Event = {
  Input: React.ChangeEvent<HTMLInputElement>;
  TextArea: React.ChangeEvent<HTMLTextAreaElement>;
  Button: React.MouseEvent<HTMLButtonElement>;
  Select: React.ChangeEvent<HTMLSelectElement>;
  Div: React.MouseEvent<HTMLDivElement>;
  Keyboard: React.KeyboardEvent<HTMLInputElement>;
};
interface ImageData {
  url: string;
  uploadUrl: string;
  extension: string;
  fileId: string;
}

interface UserActivity {
  activity: {
    image: string;
    name: string;
    path: string;
    timeAgo: string;
    type: string;
  };
  fullName: string;
  userId: string;
}

interface UserExternalAccounts {
  discordId: string;
  steamId: string;
  twitterId: string;
}

interface GettingStartedStep {
  gettingStartedStepId: string;
  order: number;
  title: string;
  isDone: boolean;
  type: 'LINK' | 'DOWNLOAD';
  url: string;
}

export interface UserData {
  userId: string;
  orgId: string;
  username: string;
  email: string;
  isActive: boolean;
  isFirstTimeUser: boolean;
  createdAt: string;
  isAgreedToLicense: boolean;
  isDemo: boolean;
  earnings: string;
  points: {
    amount: string;
  };
  verified: {
    isEmail: boolean;
  };
  scopes: string[];
  role: UserRole;
  stats: {
    games: string;
    assets: string;
    value: string;
  };
  walletAddress: string;
  data: {
    firstName: string;
    lastName: string;
  };
  image: ImageData;
  externalAccounts: UserExternalAccounts;
  transactions: TransactionItems;
  gettingStartedSteps: GettingStartedStep[];
  balance: {
    balance: string;
    transactions?: BalanceItems;
  };
  notifications: [GeneralNotificationData];
  userActivity: UserActivity[];
  nfts: {
    nftId: number;
    rarity: 'EPIC' | 'BORDERLESS' | 'LEGENDARY';
    multiplier: string;
    isStaked: boolean;
  }[];
  stakedNft: {
    nftId: number;
    rarity: string;
    multiplier: string;
    isStaked: boolean;
  };
}

export interface CustomFile {
  size?: string;
  fileId: string;
  isIpfs: boolean;
  extension: string;
  uploadUrl: string;
  url: string;
  name: string;
}

export interface AttributesData {
  displayType: 'string' | 'boost_percentage' | 'date' | 'number';
  maxValue?: string;
  traitType: string;
  values: string[];
  selectedValue?: string;
  __typename?: string;
}

export interface UsersData {
  items: UserData[];
}

export interface TransactionItemData {
  ownableAssetId: string;
  date: string;
  cId: string;
  charge: {
    currency: string;
    value: string;
  };
  gameId: string;
  gasFees: {
    chainId: number;
    value: string;
  };
  nftId: string;
  receiver: {
    name: string;
    imageUrl: string;
    walletAddress: string;
  };
  receiverWalletAddress: string;
  sender: {
    name: string;
    imageUrl: string;
    walletAddress: string;
  };
  senderUserId: string;
  status: TransactionStatus;
  transactionId: string;
  type: TransactionType;
  balanceTransaction: BalanceItemData;
}

export interface TransactionItems {
  items: TransactionItemData[];
}

export interface UserReferralData {
  referralCode: string;
  referrals: { items: UserData[] };
}

export interface BalanceItemData extends TransactionItemData {
  date: string;
  amount: number;
  currency: string;
  transactionId: string;
}

export interface BalanceItems {
  items: BalanceItemData[];
}

export interface GameData {
  approvedUserScopes: [string];
  chain: ChainData;
  updatedAt: string;
  description: string;
  gameConnect: {
    status: string;
  };
  stats: {
    walletAssets: string;
    mintedAssets: string;
    value: string;
    players: string;
  };
  adCampaignStats: {
    impressions: string;
  };
  gameId: string;
  image: ImageData;
  coverImage?: ImageData;
  isActive: boolean;
  name: string;
  orgId: string;
  players: UsersData;
  walletAddress: string;
  urls: {
    type: string;
    url: string;
  }[];
  category?: string;
  apiCredentials: {
    apiKey: string;
    apiSecret: string;
    expiry: string;
    isTestNet: boolean;
  }[];
  transactions: TransactionItems;
  iosData?: {
    bundleId: string;
    url: string;
  };
  androidData?: {
    url: string;
    packageName: string;
  };
  webData?: {
    url: string;
  };
}

export interface GamesData {
  items: [GameData];
}

export interface CampaignsData {
  items: [CampaignData];
}

export interface GameConnectData {
  game: GameData;
  gameId: string;
  status: string;
  user: UserData;
  userId: string;
}

export interface OrganizationData {
  data: {
    description: string;
    discord: string;
    twitter: string;
    url: string;
  };
  stats: {
    games: string;
    adCampaigns: string;
    collections: string;
    mintedAssets: string;
    players: string;
  };
  adCampaigns: CampaignsData;
  games: GamesData;
  image: ImageData;
  isActive: boolean;
  members: UsersData;
  name: string;
  type: OrgType;
  orgId: string;
  mintLimit: number;
  mintCount: number;
  updatedAt: string;
  balance: {
    balance: string;
    transactions: BalanceItems;
  };
  userActivity: UserActivity[];
  brands: BrandsList;
}

export interface BrandsList {
  items: Brand[];
}

export interface Brand {
  brandId: string;
  description: string;
  image: ImageData;
  name: string;
  updatedAt: string;
  website: string;
}
export interface GeneralNotificationData {
  userId: string;
  message: string;
  type: NotificationType;
  meta: {
    key: string;
    value: string;
  }[];
  notificationId: string;
  isRead: string;
}

export interface GameConnect {
  userId: string;
  gameId: string;
  status: GameStatus;
  name: string;
}

export interface GameAuthorization {
  authId: string;
  userId: string;
  gameId: string;
  authorized: boolean;
  name: string;
}

export interface TransactionModalData {
  show: boolean;
  success?: boolean;
  msg?: string;
}
export interface ChainData {
  chainSymbol: string;
  mainnet: {
    chainId: number;
    name: string;
    isActive: boolean;
  };
  name: string;
  testnet: {
    chainId: number;
    name: string;
  };
}
export interface ChainsData {
  items: [ChainData];
}

export interface ChainOption {
  name: string;
  chainId: number;
}

export interface AdData {
  adCampaignIds: string[];
  adId: string;
  adType: AdType;
  banner: ImageData;
  video: ImageData;
  name: string;
  updatedAt: string;
  isActive: boolean;
  interactionHtml: string;
  stats: {
    impressions: string;
    interactions: string;
    cost: string;
  };
  contentTags: Content[];
  genres: string[];
}

export interface AdsData {
  items: [AdData];
}

export interface BrandedObjectsData {
  items: [BrandedObjectData];
}

export interface Promotions {
  items: [Promotion];
}

export interface Promotion {
  buttonText: string;
  buttonUrl: string;
  message: string;
  promotionId: string;
  promotionImage: CustomFile;
  isExternalUrl: boolean;
  title: string;
}
export interface CampaignData {
  adCampaignId: string;
  adType: AdType;
  assetType: CampaignAssetType;
  ads: AdsData;
  updatedAt: string;
  ageFrom: number;
  ageTo: number;
  brand: Brand;
  brandedObjects: BrandedObjectsData;
  campaignImage: ImageData;
  campaignGoal: CampaignGoal;
  adCampaignObjectiveType: CampaignGoal;
  description: string;
  gender: [Gender.MALE, Gender.FEMALE, Gender.OTHER];
  name: string;
  startDate: string;
  endDate: string;
  targetMarket: string;
  isDemo: boolean;
  totalAdCount: string;
  status: 'DRAFT' | 'FINISHED' | 'IN_PROGRESS' | 'SCHEDULED' | 'UNSCHEDULED' | 'STOPPED';
  usedImpressions: number;
  totalImpressions: number;
  totalCost: number;
  usedCost: number;
  chainId: number;
  web3Only: boolean;
  checkpoint: AdCampaignCheckpoint;
  targetCountries: {
    countryCode: string;
    countryName: string;
  }[];
  contentTags: Content[];
  adCampaignGenres: { name: string }[];
  budget: {
    pricingType: AdType;
    countryCode: string;
    countryName: string;
    cpm: number;
    region: string;
    totalImpressions: number;
  }[];
}

export type UpsertCampaignAction = 'UNSCHEDULED' | 'STOPPED' | 'SCHEDULED';

export interface PublicCampaignData {
  adCampaignId: string;
  adCampaignType: 'RENEVERSE' | 'ORGANIZATION';
  campaignImage: ImageData;
  description: string;
  startDate: Date;
  endDate: Date;
  name: string;
  actions: {
    actionId: string;
    amount: string;
    fulfilled: boolean;
    type: string;
    description: string;
    url: string;
  }[];
  leaderboard: {
    items: {
      amount: string;
      rank: string;
      user: UserData;
    }[];
  };
}

export interface PublicCampaignsData {
  items: PublicCampaignData[];
}

export interface AdSurfaceData {
  adSurfaceId: string;
  adType: string;
  interactivity: AdSurfaceInteractivity;
  resolutionIab: AdResolutionIab;
  updatedAt: string;
  floorPrice: number;
  maxWidth: string;
  maxHeight: string;
  adCampaignStats: {
    impressions: string;
  };
  contentTags: {
    name: string;
    taxonomyContentId: string;
  }[];
  file?: File;
  placeholder:
    | {
        extension: string;
        uploadUrl: string;
        url: string;
      }
    | undefined;
}

export interface ContentSearchData {
  items: Content[];
}

export interface Content {
  name: string;
  taxonomyContentId: string;
  tier1: string;
  tier2: string | null;
  tier3: string | null;
  tier4: string | null;
  __typename?: string;
}

export type FloorPricePerAd = {
  adType: AdType;
  price: number;
};

export type AdOfferData = {
  adOfferId: string;
  adOfferImage: CustomFile;
  brand?: Brand;
  buttonText: string;
  buttonUrl: string;
  createdAt: string;
  message: string;
  title: string;
  updatedAt: string;
};

export type AdOffer = {
  isViewed: boolean;
  userAdOfferId: string;
  adOffer: AdOfferData;
};

export type AdOffers = {
  items: AdOffer[];
  limit: String;
  unviewedCount: number;
};

export type GameOperatingSystem = 'ANDROID' | 'IOS' | 'WEB';

export type PromotionData = {
  promotionId: string;
  title: string;
  message: string;
  buttonText: string;
  buttonUrl: string;
  promotionImage: CustomFile;
};

export type BrandedObjectSize = 'SMALL' | 'MEDIUM' | 'LARGE' | 'EXTRA_LARGE' | 'EXTRA_SMALL';
export type BrandedObjectType = AdType.THREE_D | AdType.TWO_D;

export type BrandedObjectData = {
  adCampaignId: string;
  brandedObjectId: string;
  brandedObjectSize: BrandedObjectSize;
  brandedObjectType: BrandedObjectType;
  contentTags: Content[];
  depth: number;
  description: string;
  height: number;
  image: CustomFile;
  model: CustomFile;
  name: string;
  texture: CustomFile;
  updatedAt: string;
  width: number;
  genres: string[];
};

export type Region = {
  code: string;
  name: string;
};

export type Country = {
  code: string;
  name: string;
};

export type RegionWithCountries = {
  countries: Country[];
  region: Region;
};

export type RegionsAndCountriesData = {
  items: RegionWithCountries[];
};

export type ReachEstimateEntry = {
  campaignReachEstimate: number;
  countryCode: string;
  gameCount: number;
  globalMonthlyActiveUsers: number;
  impressionsEstimate: number;
  regionMonthlyActiveUsers: number;
  regionVersusGlobalPercentage: number;
};

export type ReachEstimateData = {
  campaignReachEstimate: number;
  entries: ReachEstimateEntry[];
  gameCount: number;
  globalMonthlyActiveUsers: number;
  impressionsEstimate: number;
  regionMonthlyActiveUsers: number;
  regionVersusGlobalPercentage: number;
};

export type ReachEstimateInput = {
  countryCodes: string[] | null;
  endDate: string | null;
  startDate: string | null;
};

export type PricingItem = {
  adType: AdType;
  countryCode: string;
  countryName: string;
  cpm: number;
  region: string;
  totalImpressions: number;
};

export type PricingData = {
  Pricing: {
    items: PricingItem[];
  };
};

export type PricingType = keyof typeof AdType | 'THREE_D' | 'TWO_D';
export const isValidPricingType = (type: unknown): type is PricingType => {
  return AdType[type as keyof typeof AdType] !== undefined || type === 'THREE_D' || type === 'TWO_D';
};

export type PricingInput = {
  adTypes: PricingType[];
  countries: string[];
};

export type UpsertBrandedObjectPlacementInput = {
  height: number;
  length: number;
  width: number;
  categories?: string[];
  gameId: string;
  orgId?: string;
  type?: PricingType;
  brandedObjectPlacementId?: string;
};

export type BrandedObjectPlacement = {
  brandedObjectPlacementId?: string;
  categories?: string[];
  gameId?: string;
  height: number;
  length: number;
  orgId?: string;
  width: number;
  type?: PricingType;
  scale?: number;
  updatedAt: string;
  createdAt: string;
};
