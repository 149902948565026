import { useEffect, useState } from 'react';
import {
  AdData,
  BrandedObjectData,
  CampaignData,
  CampaignsData,
  Content,
  OrganizationData,
  PricingItem,
  Refetch,
} from '../../../global/interfaces';
import { AdCampaignCheckpointSteps, CampaignGoal, CampaignStatus, Gender } from '../../../global/consts';
import { useReneMutation } from '../../../hooks';
import { DELETE_AD_CAMPAIGN_MUTATION } from '../../../global/gql/mutations';
import { useApolloClient } from '@apollo/client';
import { GET_AD_CAMPAIGN_QUERY, GET_AD_CAMPAIGNS_QUERY } from '../../../global/gql/queries';
import Icon from '../../Icon/Icon';
import Budget from './steps/budget/budget';
import Review from './steps/review/review';
import SelectAd from './steps/select-ad/select-ad';
import Overview from './steps/overview/overview';
import AdsPlacement from './steps/ads-placement/ads-placement';

import './create-campaign-modal.scss';

export interface CampaignFormData {
  adCampaignId: string;
  status: CampaignStatus;
  overview: {
    name: string;
    adCampaignObjectiveType: CampaignGoal;
    market: string[];
    ageRange: { min: number; max: number };
    targetCountries: {
      countryCode: string;
      countryName: string;
    }[];
    gender: Gender[] | [];
    chainId: string;
  };
  selectedAdsIds: string[];
  selectedBrandedObjectsIds: string[];
  selectedAds: AdData[];
  selectedBrandedObjects: BrandedObjectData[];
  adsPlacement: {
    contentTags: Content[];
    genres: string[];
  };
  budget: {
    startDate: string;
    endDate: string;
    pricing: PricingItem[];
  };
}

const steps = [
  {
    title: 'Overview & Target Audience',
    order: 1,
  },
  {
    title: 'Select Ad',
    order: 2,
  },
  {
    title: 'Ads placement',
    order: 3,
  },
  {
    title: 'Budget & Scheduling',
    order: 4,
  },
  {
    title: 'Review & Launch',
    order: 5,
  },
];

const CreateCampaignModal = ({
  refetch,
  setCloseModal,
  previousCampaign,
}: {
  refetch: Refetch<{ Organization: OrganizationData } | { AdCampaigns: CampaignsData } | undefined>;
  setCloseModal: () => void;
  previousCampaign?: CampaignData;
}) => {
  const queryClient = useApolloClient();

  const [activeStep, setActiveStep] = useState(1);
  const [formData, setFormData] = useState<CampaignFormData>({
    adCampaignId: '',
    status: CampaignStatus.DRAFT,
    overview: {
      name: '',
      adCampaignObjectiveType: CampaignGoal.BRAND_AWARENESS,
      market: [],
      targetCountries: [],
      ageRange: { min: 18, max: 65 },
      gender: [],
      chainId: '',
    },
    selectedAdsIds: [],
    selectedBrandedObjectsIds: [],
    selectedAds: [],
    selectedBrandedObjects: [],
    adsPlacement: {
      contentTags: [],
      genres: [],
    },
    budget: {
      startDate: '',
      endDate: '',
      pricing: [],
    },
  });

  const [deleteAdCampaign, { loading: deleteAdCampaignLoading }] = useReneMutation(DELETE_AD_CAMPAIGN_MUTATION, {
    variables: {
      adCampaignId: formData.adCampaignId,
    },
    onCompleted: () => {
      queryClient.refetchQueries({ include: [GET_AD_CAMPAIGN_QUERY, GET_AD_CAMPAIGNS_QUERY] });
      setCloseModal();
    },
  });

  useEffect(() => {
    if (previousCampaign) {
      setFormData((prevFormData) => ({
        ...prevFormData,
        adCampaignId: previousCampaign?.adCampaignId as string,
        status: previousCampaign?.status as CampaignStatus,
        overview: {
          ...prevFormData.overview,
          chainId: previousCampaign?.chainId?.toString() || '',
          name: previousCampaign?.name as unknown as string,
          targetCountries: previousCampaign?.targetCountries ?? [],
          gender: previousCampaign?.gender ?? [],
        },
        selectedAds: previousCampaign?.ads?.items ?? [],
        selectedAdsIds: previousCampaign?.ads?.items.map((ad) => ad.adId) ?? [],
        selectedBrandedObjects: previousCampaign?.brandedObjects?.items ?? [],
        selectedBrandedObjectsIds:
          previousCampaign?.brandedObjects?.items.map((brandedObject) => brandedObject.brandedObjectId) ?? [],
        adsPlacement: {
          contentTags: previousCampaign?.contentTags ?? [],
          genres: previousCampaign?.adCampaignGenres.map((c) => c.name) ?? [],
        },
        budget: {
          startDate: previousCampaign?.startDate ?? '',
          endDate: previousCampaign?.endDate ?? '',
          pricing:
            previousCampaign?.budget.map((item) => ({
              adType: item.pricingType,
              countryCode: item.countryCode,
              countryName: item.countryName,
              cpm: item.cpm,
              region: item.region,
              totalImpressions: item.totalImpressions,
            })) ?? [],
        },
      }));
    }
  }, [previousCampaign]);

  useEffect(() => {
    if (previousCampaign) {
      setActiveStep(AdCampaignCheckpointSteps[previousCampaign.checkpoint]);
    }
  }, [previousCampaign]);

  const handleDeleteAdCampaign = () => {
    if (formData.adCampaignId) {
      return deleteAdCampaign({ variables: { adCampaignId: formData.adCampaignId } });
    }
    return setCloseModal();
  };
  console.log(formData);
  return (
    <div className="create-campaign-modal full-size">
      <div className="create-campaign-modal__heading">
        <h1>Create campaign</h1>
        <button onClick={setCloseModal}>
          <Icon name="close" />
        </button>
      </div>
      <aside className="create-campaign-modal__aside">
        {steps.map((step) => (
          <button
            key={step.order}
            className={`create-campaign-modal__aside-item ${
              activeStep === step.order ? 'create-campaign-modal__aside-item--active' : ''
            } ${activeStep > step.order ? 'create-campaign-modal__aside-item--completed' : ''}`}
          >
            <div>{activeStep <= step.order ? <p>{step.order}</p> : <Icon name="checked" size={24} />}</div>
            <p>{step.title}</p>
          </button>
        ))}
      </aside>
      <div className="create-campaign-modal__main">
        {activeStep === 1 && (
          <Overview
            key={formData.adCampaignId}
            adCampaignId={formData.adCampaignId}
            status={formData.status}
            data={formData.overview}
            setFormData={setFormData}
            setActiveStep={setActiveStep}
            handleDeleteAdCampaign={handleDeleteAdCampaign}
            deleteAdCampaignLoading={deleteAdCampaignLoading}
            refetch={refetch}
          />
        )}
        {activeStep === 2 && (
          <SelectAd
            key={formData.selectedAdsIds.length + formData.selectedBrandedObjectsIds.length}
            status={formData.status}
            data={{
              selectedAdsIds: formData.selectedAdsIds,
              selectedBrandedObjectsIds: formData.selectedBrandedObjectsIds,
            }}
            setFormData={setFormData}
            setActiveStep={setActiveStep}
            adCampaignId={formData.adCampaignId}
            handleDeleteAdCampaign={handleDeleteAdCampaign}
            deleteAdCampaignLoading={deleteAdCampaignLoading}
            refetch={refetch}
          />
        )}
        {activeStep === 3 && (
          <AdsPlacement
            key={formData.adCampaignId}
            status={formData.status}
            data={{
              selectedAds: formData.selectedAds,
              selectedBrandedObjects: formData.selectedBrandedObjects,
              contentTags: formData.adsPlacement.contentTags,
              genres: formData.adsPlacement.genres,
            }}
            adCampaignId={formData.adCampaignId}
            setActiveStep={setActiveStep}
            setFormData={setFormData}
            handleDeleteAdCampaign={handleDeleteAdCampaign}
            deleteAdCampaignLoading={deleteAdCampaignLoading}
            refetch={refetch}
          />
        )}
        {activeStep === 4 && (
          <Budget
            key={formData.adCampaignId}
            status={formData.status}
            adCampaignId={formData.adCampaignId}
            data={{
              startDate: formData.budget.startDate,
              endDate: formData.budget.endDate,
              targetCountries: formData.overview.targetCountries,
              selectedAds: formData.selectedAds,
              selectedBrandedObjects: formData.selectedBrandedObjects,
              pricing: formData.budget.pricing,
            }}
            setActiveStep={setActiveStep}
            setFormData={setFormData}
            handleDeleteAdCampaign={handleDeleteAdCampaign}
            deleteAdCampaignLoading={deleteAdCampaignLoading}
            refetch={refetch}
          />
        )}
        {activeStep === 5 && (
          <Review
            formData={formData}
            key={formData.adCampaignId}
            setActiveStep={setActiveStep}
            setCloseModal={setCloseModal}
            handleDeleteAdCampaign={handleDeleteAdCampaign}
            deleteAdCampaignLoading={deleteAdCampaignLoading}
            refetch={refetch}
          />
        )}
      </div>
    </div>
  );
};

export default CreateCampaignModal;
